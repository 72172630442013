<template>
  <div class="assignment-single">
    <div class="container" ref="assignment_head">
      <div v-if="assignmentLoading" class="loading">Loading...</div>
      <div v-else-if="assignment" class="info-section">
        <div class="_section-head">
          <h5>Assignment:- {{ assignment.title }}</h5>
          <h4>
            <span>Total Marks: {{ assignment.total_marks }}</span>
            <span>Subject: {{ assignment.subject.subject_title }}</span>
            <span>Faculty: {{ assignment.faculty.faculty_name }}</span>
          </h4>
        </div>
        <div class="_section-body">
          <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
            <li class="nav-item" role="presentation">
              <button
                class="nav-link active"
                id="pills-Instructions-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-Instructions"
                type="button"
                role="tab"
                aria-controls="pills-Instructions"
                aria-selected="true"
              >
                Instructions
              </button>
            </li>
            <li
              v-if="!isPublished && assignment && !assignment.is_expired"
              class="nav-item"
              role="presentation"
            >
              <button
                class="nav-link"
                id="pills-submission-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-submission"
                type="button"
                role="tab"
                aria-controls="pills-submission"
                aria-selected="false"
              >
                my submission
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button
                class="nav-link"
                id="pills-allsubmission-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-allsubmission"
                type="button"
                role="tab"
                aria-controls="pills-allsubmission"
                aria-selected="false"
                ref="review_submission"
                @click="fetchSubscribedProgramAssignment"
              >
                review submission
              </button>
            </li>
            <li v-if="isPublished" class="nav-item" role="presentation">
              <button
                class="nav-link"
                id="pills-feedback-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-feedback"
                type="button"
                role="tab"
                aria-controls="pills-feedback"
                aria-selected="false"
              >
                feedback
              </button>
            </li>
          </ul>
          <div class="tab-content" id="pills-tabContent">
            <div
              class="tab-pane fade show active"
              id="pills-Instructions"
              role="tabpanel"
              aria-labelledby="pills-Instructions-tab"
            >
              <div class="assignment-item">
                <p>{{ assignment.description }}</p>
                <p>
                  <span
                    >Last date to submit:
                    {{ assignment.converted_last_date }}</span
                  >
                </p>
                <div
                  v-if="assignment && assignment.file != null"
                  class="Instruction-box"
                >
                  <p>
                    <img src="../assets/pdf.png" />
                    Instruction
                  </p>
                  <a
                    href="#"
                    class="btn small-cta"
                    @click.prevent="
                      downloadFile(assignment.file, 'instructions.pdf')
                    "
                  >
                    <!-- <i class="fas fa-file-download"></i>  -->
                    Download</a
                  >
                </div>
              </div>
            </div>
            <div
              v-if="!isPublished && assignment && !assignment.is_expired"
              class="tab-pane fade"
              id="pills-submission"
              role="tabpanel"
              aria-labelledby="pills-submission-tab"
            >
              <h4>Save assignment</h4>
              <p>Upload the files in pdf format (Max 5 MB)</p>
              <form @submit.prevent="saveAssignment" ref="assignmentContainer">
                <div class="upload-div">
                  <div class="input-group mb-3">
                    <input
                      type="file"
                      multiple
                      class="form-control"
                      id="inputGroupFile02"
                      @change="selectFiles"
                    />
                    <label class="input-group-text" for="inputGroupFile02"
                      >Upload files</label
                    >
                  </div>
                  <div v-if="selectedFiles.length > 0">
                    <p>Selected files:</p>
                    <ul
                      v-for="(file, fileIndex) in selectedFiles"
                      :key="fileIndex"
                    >
                      <li>{{ file.name }}</li>
                    </ul>
                  </div>
                  <div
                    v-if="error && error.fileTypeError"
                    class="mb-3 error-msg"
                  >
                    {{ error.fileTypeError }}
                  </div>
                </div>
                <div class="form">
                  <textarea
                    class="form-control"
                    placeholder="Leave your assignment note here..."
                    v-model.trim="assignmentNote"
                  ></textarea>
                  <!-- <div
                    class="input-errors"
                    v-for="error of v$.assignmentNote.$errors"
                    :key="error.$uid"
                  >
                    <div class="error-msg">{{ error.$message }}</div>
                  </div> -->
                </div>
                <div v-if="!isPublished" class="btn-row text-right d-flex pt-5">
                  <button
                    type="submit"
                    :disabled="isButtonDisabled"
                    class="btn block-cta"
                  >
                    <span v-if="!isSaving"> Save </span>
                    <span v-else> Saving... </span>
                  </button>
                </div>
              </form>
              <div v-if="progressInfos">
                <div
                  class="mb-2"
                  v-for="(progressInfo, index) in progressInfos"
                  :key="index"
                >
                  <span>{{ progressInfo.fileName }}</span>
                  <div class="progress">
                    <div
                      class="progress-bar progress-bar-info"
                      role="progressbar"
                      :aria-valuenow="progressInfo.percentage"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      :style="{ width: progressInfo.percentage + '%' }"
                    >
                      {{ progressInfo.percentage }}%
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="message" role="alert">
                <ul>
                  {{
                    message
                  }}
                </ul>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="pills-allsubmission"
              role="tabpanel"
              aria-labelledby="pills-allsubmission-tab"
            >
              <div class="assignment-item" v-if="uploadedData">
                <p>Assignment Note:</p>
                <p>
                  <span>{{ uploadedData.assignment_note }}</span>
                </p>
                <p>Uploaded files:</p>
                <div class="" v-if="uploadedData.files.length > 0">
                  <div
                    class="col-md-3 Instruction-box"
                    v-for="file in uploadedData.files"
                    :key="file.id"
                  >
                    <img src="../assets/pdf.png" />
                    <p>{{ file.original_file_name }}</p>
                    <p></p>
                    <a
                      href="#"
                      class="btn small-cta"
                      @click.prevent="
                        downloadFile(file.file, file.original_file_name)
                      "
                      ><i class="fas fa-download"></i
                    ></a>
                    <a
                      class="btn small-cta delete"
                      href="#"
                      @click.prevent="deleteFile(file.id)"
                      v-if="!isPublished"
                      ><i class="fas fa-trash-alt"></i
                    ></a>
                  </div>
                </div>
                <p v-else>No files uploaded</p>
              </div>
              <div v-else>
                <EmptyList
                  v-if="!assignment.is_expired"
                  messageText="Save your assignment first!"
                />
                <EmptyList
                  v-else
                  messageText="Last date to submit the assignment has been expired!"
                />
              </div>
              <div
                v-if="!isPublished && assignment && !assignment.is_expired"
                class="btn-row text-right d-flex pt-5"
              >
                <button
                  @click="submitAssignment"
                  :disabled="isButtonDisabled"
                  ref="submitAssignmentContainer"
                  class="btn block-cta"
                >
                  <span v-if="!isSubmitting"> Submit </span>
                  <span v-else> Submitting... </span>
                </button>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="pills-feedback"
              role="tabpanel"
              aria-labelledby="pills-feedback-tab"
            >
              <div class="assignment-item" v-if="feedbackData">
                <p>Assignment Remark:</p>
                <p>
                  <span>{{ feedbackData.remarks }}</span>
                </p>
                <p>Mark:</p>
                <p>
                  <span>{{ feedbackData.mark }}</span>
                </p>
              </div>
              <EmptyList
                v-else
                messageText="Your submission hasn't been evaluated yet!"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AssignmentService from "@/services/AssignmentService";
import EmptyList from "@/components/EmptyList.vue";
export default {
  name: "Assignment",
  components: {
    EmptyList,
  },
  data() {
    return {
      assignmentLoading: true,
      isSaving: false,
      isSubmitting: false,
      isPublished: false,
      isButtonDisabled: false,
      assignment: null,
      uploadedData: null,
      feedbackData: null,
      selectedFiles: [],
      progressInfos: [],
      assignmentNote: "",
      fileUploadCounter: 0,
      fullPage: true,
      error: {
        fileTypeError: "",
        fileSizeError: "",
      },
      message: "",
    };
  },
  created() {
    this.fetchSubscribedProgramAssignment();
  },
  methods: {
    async fetchSubscribedProgramAssignment() {
      let params = {
        program_id: this.$route.params.program_id,
        assignment_id: this.$route.params.assignment_id,
      };
      await AssignmentService.getSubscribedProgramAssignment(params)
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            this.assignmentLoading = false;
            this.assignment = response.data.assignment;
            if (this.assignment.submissions !== null) {
              this.uploadedData = this.assignment.submissions;
              this.feedbackData = this.uploadedData.feedback;
              this.assignmentNote = this.uploadedData.assignment_note;
              if (this.uploadedData.status === "PUBLISHED") {
                this.isPublished = true;
              }
            }
          }
          if (response.data.status === "ERROR") {
            this.$toast.error(response.data.message);
          }
          if (response.data.status === "VALIDATION_FAILED") {
            let errorsObject = response.data.errors;
            let errorValuesArray = Object.values(errorsObject);
            if (errorValuesArray.length > 0) {
              for (let index = 0; index < errorValuesArray.length; index++) {
                this.$toast.error(errorValuesArray[index]);
              }
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    selectFiles(event) {
      this.progressInfos = [];
      this.selectedFiles = event.target.files;
      this.error.fileTypeError = "";
      for (let i = 0; i < this.selectedFiles.length; i++) {
        if (this.selectedFiles[i]["type"] !== "application/pdf") {
          this.error.fileTypeError = "Please upload pdf files only";
        }
      }
    },
    saveAssignment() {
      let loader = this.$loading.show({
        // Optional parameters
        container: this.fullPage ? null : this.$refs.assignmentContainer,
      });
      let params = {
        assignment_id: this.assignment.id,
        assignment_note: this.assignmentNote,
        program_id: this.$route.params.program_id,
      };
      if (this.error.fileTypeError != "") {
        return null;
      } else {
        this.error.fileTypeError = "";
      }
      this.isSaving = true;
      this.isButtonDisabled = true;
      this.message = "";
      AssignmentService.saveAssignment(params)
        .then((response) => {
          loader.hide();
          this.isSaving = false;
          this.isButtonDisabled = false;
          if (response.data.status === "SUCCESS") {
            if (this.selectedFiles.length == 0) {
              this.$toast.success(response.data.message);
              this.fetchSubscribedProgramAssignment();
              this.$refs.review_submission.click();
              this.scrollToElement(this.$refs.assignment_head);
            } else if (this.selectedFiles.length > 0) {
              for (let i = 0; i < this.selectedFiles.length; i++) {
                this.uploadFiles(
                  i,
                  this.selectedFiles[i],
                  response.data.assignment_upload_id,
                  this.$route.params.assignment_id
                );
              }
            }
          }
          if (response.data.status === "ERROR") {
            this.$toast.error(response.data.message);
          }
          if (response.data.status === "VALIDATION_FAILED") {
            let errorsObject = response.data.errors;
            let errorValuesArray = Object.values(errorsObject);
            if (errorValuesArray.length > 0) {
              for (let index = 0; index < errorValuesArray.length; index++) {
                this.$toast.error(errorValuesArray[index]);
              }
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    uploadFiles(idx, file, uploadId, assignmentId) {
      this.progressInfos[idx] = {
        percentage: 0,
        fileName: file.name,
      };
      AssignmentService.uploadAssignmentFiles(
        file,
        uploadId,
        assignmentId,
        (event) => {
          this.progressInfos[idx].percentage = Math.round(
            (100 * event.loaded) / event.total
          );
          if (this.progressInfos[idx].percentage == 100) {
            this.fileUploadCounter++;
          }
        }
      )
        .then((response) => {
          this.isSaving = false;
          this.isButtonDisabled = false;
          if (this.selectedFiles.length == this.fileUploadCounter) {
            this.$toast.success(response.data.message);
            this.selectedFiles = [];
            this.progressInfos = [];
            this.$refs.review_submission.click();
            this.scrollToElement(this.$refs.assignment_head);
          }

          if (response.data.status === "ERROR") {
            this.$toast.error(response.data.message);
          }
          if (response.data.status === "VALIDATION_FAILED") {
            let errorsObject = response.data.errors;
            let errorValuesArray = Object.values(errorsObject);
            if (errorValuesArray.length > 0) {
              for (let index = 0; index < errorValuesArray.length; index++) {
                this.$toast.error(errorValuesArray[index]);
              }
            }
            this.progressInfos[idx].percentage = 0;
            this.fileUploadCounter--;
            this.message = "Could not upload the file: " + file.name;
            this.selectedFiles = [];
          }
        })
        .catch((error) => {
          console.error(error);
          this.progressInfos[idx].percentage = 0;
          this.fileUploadCounter--;
          this.message = "Could not upload the file: " + file.name;
          this.selectedFiles = [];
        });
    },
    submitAssignment() {
      let loader = this.$loading.show({
        // Optional parameters
        container: this.fullPage ? null : this.$refs.submitAssignmentContainer,
      });
      let params = {
        assignment_id: this.assignment.id,
        program_id: this.$route.params.program_id,
      };
      this.isSubmitting = true;
      this.isButtonDisabled = true;
      AssignmentService.submitAssignment(params)
        .then((response) => {
          loader.hide();
          if (response.data.status === "SUCCESS") {
            this.$toast.success(response.data.message);
            this.$router.push({
              name: "MyCourses",
            });
          }
          if (response.data.status === "ERROR") {
            this.isSubmitting = false;
            this.isButtonDisabled = false;
            this.$toast.error(response.data.message);
          }
          if (response.data.status === "VALIDATION_FAILED") {
            this.isSubmitting = false;
            this.isButtonDisabled = false;
            let errorsObject = response.data.errors;
            let errorValuesArray = Object.values(errorsObject);
            if (errorValuesArray.length > 0) {
              for (let index = 0; index < errorValuesArray.length; index++) {
                this.$toast.error(errorValuesArray[index]);
              }
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    downloadFile: function (file, filename) {
      AssignmentService.downloadFile(file)
        .then((response) => {
          let blob = new Blob([response.data], { type: "application/pdf" });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = filename;
          link.click();
        })
        .catch((error) => {
          console.error(error);
        });
    },
    deleteFile(id) {
      AssignmentService.deleteFile({ assignment_upload_file_id: id })
        .then(() => {
          this.$toast.success("Successfully deleted");
          this.fetchSubscribedProgramAssignment();
        })
        .catch((error) => {
          console.error(error);
        });
    },
    scrollToElement(refId) {
      const el = refId;
      if (el) {
        el.scrollIntoView({
          behavior: "auto",
          block: "start",
          inline: "nearest",
        });
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/style/assignment-single.scss";
</style>
